<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/07/KM6020-aperto-rev.1-210611-DEF-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">


                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        The unwinder model KM 6020 was built as a device for feeding the cable in
                                        automatic machines. This model can be considered an operating station in its own
                                        right; it is, in fact, completely autonomous and independent of the type of
                                        machine it is associated with. The unwinding speed of the reel is regulated by a
                                        potentiometer which, associated with a large warehouse, can supply yarn from
                                        very small reels even to very fast machines. </p>
                                </div>

                                <br />
                                   <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    TECHNICAL DATA
                                </h1>
                                <br />
                                <h3 style="color: black; font-weight: 800">Maximum reel diameter </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    400 mm
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Maximum reel </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    weight between 30kg and 35kg  
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Wire magazine </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    4 m 
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Turns </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    maximum 
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Power supply </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    3 x 400 VAC 50/60 Hz 
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Dimensions </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    L 1000 mm x P 800 mm x H 1200 mm 

 
                                </p>

                                <br />
                                 <h3 style="color: black; font-weight: 800">Weight </h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                   100 kg
                                </p>

                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">

                                    Data and measurements are indicative and subject to change without notice. Some
                                    particular types of cables may not be machined even though they fall within the
                                    range of sections indicated. KM Corporate will still be happy to carry out tests and
                                    supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />



                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {

        };

    },

    methods: {

    }
};
</script>